import { Component, OnInit, Input } from '@angular/core';
import { CarousalService } from 'src/app/modules/shared/services/carousal.service';
import * as moment from 'moment';

@Component({
  selector: 'app-slide-hygiene-updates',
  templateUrl: './slide-hygiene-updates.component.html',
  styleUrls: ['./slide-hygiene-updates.component.scss']
})
export class SlideHygieneUpdatesComponent implements OnInit {
  @Input() slideNumber;
  @Input() atpData:any={};
  @Input() location;
  @Input() atpBackgroundImage ='../../../../../../assets/images/weather-cast/No_Data.jpg';
  @Input() hideAddressStrip:Boolean = false;
  constructor( private carousalService: CarousalService,
    ) { }
  ngOnInit() {
  }

}
