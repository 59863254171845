import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatConfirmDialogComponent } from '../mat-confirm-dialog/mat-confirm-dialog.component';

@Component({
  selector: 'app-mat-document-viewer',
  templateUrl: './mat-document-viewer.component.html',
  styleUrls: ['./mat-document-viewer.component.scss']
})
export class MatDocumentViewerComponent implements OnInit {
  title: string;
  file: string;

  constructor(
    public dialogRef: MatDialogRef<MatConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.file = `data:image/png;base64,${data.file}`;
  }

  ngOnInit() {
  }

  onDismiss(): void {
    this.dialogRef.close(false);

  }

}
