import { Component, OnInit, Input, HostListener, OnChanges, OnDestroy, ViewChild, SimpleChanges } from '@angular/core';
import { LoaderService } from '../../../../services/loader.service';
import { ToastService } from '../../../../services/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServiceService } from '../../../../../../core/common-service.service';
import { ConfigurationFormFieldService } from '../../../../services/configuration-form-field.service';
import { FormFieldDetailModel, FormFieldListModel } from '../../../../model/configuration-form-field';
import { ToasterComponent } from '../../../toaster/toaster.component';
import { ErrorToasterComponent } from '../../../error-toaster/error-toaster.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/app/config/environments/environment';


@Component({
  selector: 'app-list-configuration-form-field',
  templateUrl: './list-configuration-form-field.component.html',
  styleUrls: ['./list-configuration-form-field.component.scss']
})
export class ListConfigurationFormFieldComponent implements OnInit, OnDestroy {
  @Input() showMobileTable: boolean;
  @Input() serviceName: string;
  @Input() type: string;
  @Input() url : string
  // @Input() backLink : string;
  noResult: boolean;
  allFields: Array<FormFieldDetailModel>;
  public data: any = [];
  saveSearch: any = '';
  fieldType = [{ key: 'INPUT_BOX', value: 'INPUT BOX' }, { key: 'TEXT_AREA', value: 'TEXT AREA' }, { key: 'DATE', value: 'DATE' }, { key: 'DROP_DOWN', value: 'DROP DOWN' }];
  activeStatus = [true, false];
  message;
  selectedFieldType;
  selectedFieldName;
  selectedActiveStatus;
  isAdditionFieldShow : boolean;
  private subscriptions: Subscription[] = [];
  constructor(
    private loaderService: LoaderService,
    private toastService: ToastService,
    public commonService: CommonServiceService,
    private configurationService: ConfigurationFormFieldService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if(this.router.url.includes('hygiene')) {
      this.isAdditionFieldShow = true;
    } else {
      this.isAdditionFieldShow = false;
    }
     this.listing();
  }
  // }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  listing() {
    this.loaderService.show();
    this.noResult = true;
    let urldata;
    if(this.serviceName==='visitor')
    {
      urldata=environment.visitorUrl;
    }
    else if(this.serviceName==='patient-service'){
      urldata=environment.patientApiUrl;
    }
    this.configurationService.getListing(urldata, this.type)
      .subscribe((response: FormFieldListModel) => {
        this.loaderService.hide();
        if (response.data.length) {
          this.noResult = false;
          this.allFields = response.data;
          this.allFields.forEach(result => {
            const index = this.fieldType.findIndex(item => result.fieldType === item.key);
            result.fieldTypeName = this.fieldType[index].value;
            result.isEdit = false;
            result.isError = false;
          })
        }
        else {
          this.noResult = true;
        }
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      })
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }
  editDetails(index) {
    this.router.navigate([`/admin/${this.url}/edit-configuration-form-field/${this.allFields[index].id}`], { queryParams: { query: this.serviceName } });
  }
}
