import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
// import { RoleService } from '../../service/role.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { WorkOrderService } from '../../services/work-order.service';
import { WorkOrderListModel, WorkOrderModel } from '../../models/work-order.model';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';

@Component({
  selector: 'app-work-order-list-view',
  templateUrl: './listview.html',
  styleUrls: ['./listview.scss'],
})
export class WorkOrderListComponent implements OnInit, OnDestroy {
  @Input() showMobileTable: boolean;
  @Input() filterValueChange: boolean;
  public pageInfo: PaginationModel = new PaginationModel();
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  workOrderList: any = [];
  noResult: boolean;
  searchEventSubscription: Subscription;
  saveSearch: any = '';
  message;
  data;
  showPagination: boolean;
  show;
  private subscriptions: Subscription[] = [];
  pageSizeArray = [10, 50, 100];
  checkedWorkorders: any = [];
  headCheckBox: boolean;
  statusArray: any = [];
  escalateStatusArray: any = [];
  branchArray: any = [];
  buildingArray: any = [];
  floorArray: any = [];
  roomArray: any = [];
  departmentArray: any = [];
  seatArray: any = [];
  bedArray: any = [];
  categoryArray: any = [];
  subCategoryArray: any = [];
  startDate: any;
  endDate: any;
  branchId = localStorage.getItem('branchId');
  industryId = localStorage.getItem('industryId');
  // currentDate: any = new Date().valueOf();
  constructor(
    public router: Router,
    // private roleservice: RoleService,
    public commonService: CommonServiceService,
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public dialog: DialogService,
    private workOrderService: WorkOrderService,
    private matDialog: MatDialog
  ) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.getListing();
    }
  }
  ngOnInit() {
    this.commonService.searchText = '';
    this.subscriptions.push(
      this.commonService.getSearchEvent().subscribe(() => {
        this.search();
      })
    );

    this.workOrderService.selectedWorkOrdersObservable.subscribe(res => {
      this.headCheckBox = false;
      this.getListing();
    })
    this.show = true;
    this.showPagination = true;
    this.getListing();
  }

  ngOnChanges(event) {
    this.statusArray = [];
    this.escalateStatusArray = [];
    this.branchArray = [];
    this.buildingArray = [];
    this.floorArray = [];
    this.departmentArray = [];
    this.roomArray = [];
    this.seatArray = [];
    this.bedArray = [];
    this.categoryArray = [];
    this.subCategoryArray = [];
    if (this.filterValueChange) {
      this.filterValueChange['status'].forEach(x => this.statusArray.push(x.code.toUpperCase()));
      this.filterValueChange['escalateStatus'].forEach(x => this.escalateStatusArray.push(x.status));
      // this.filterValueChange['branchIds'].forEach(x => this.branchArray.push(x.id));
      this.filterValueChange['buildingIds'].forEach(x => this.buildingArray.push(x.id));
      this.filterValueChange['roomIds'].forEach(x => this.roomArray.push(x.id));
      this.filterValueChange['floorIds'].forEach(x => this.floorArray.push(x.id));
      this.filterValueChange['seatIds'].forEach(x => this.seatArray.push(x.id));
      this.filterValueChange['departmentIds'].forEach(x => this.departmentArray.push(x.id));
      this.filterValueChange['bedIds'].forEach(x => this.bedArray.push(x.id));
      this.filterValueChange['categoryIds'].forEach(x => this.categoryArray.push(x.id));
      this.filterValueChange['subCategoryIds'].forEach(x => this.subCategoryArray.push(x.id));
      this.startDate = this.filterValueChange['startDate'];
      this.endDate = this.filterValueChange['endDate'];
      this.pageInfo.page = 0;
      this.getListing();
    }
  }

  mapData(item, headers) {
    return {
      heading: headers,
      content: item,
    };
  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.getListing();
  }

  getListing() {
    const body = this.mapKeysOfFilter();
    this.headCheckBox = false;
    this.checkedWorkorders = [];
    this.workOrderService.selectedWorkOrdersList = [];
    this.loaderService.show();
    this.workOrderService.getWorkOrderList(this.pageInfo, body).subscribe((response: WorkOrderModel) => {
      this.pageInfo.TotalRecord = response.data.totalElements;
      this.pageInfo.totalPages = response.data.totalPage;
      this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
      this.workOrderList = response.data.payload;
      // this.currentDate = new Date().valueOf();
      if (this.workOrderList.length) {
        this.noResult = false;
        this.workOrderList.forEach(item => {
          let hrs = 0;
          let minutes = 0;
          if (item.sla > 60) {
            hrs = Math.floor(item.sla / 60);
            minutes = item.sla - hrs * 60;
          } else {
            minutes = item.sla;
          }
          // item.escalateTime = moment(item.startTime).add(item.sla, 'minutes').valueOf();
          item['expectedClosureTime'] = (hrs && minutes) ? `${hrs} hours : ${minutes} min` : (hrs ? `${hrs} hours` : minutes ? `${minutes} minutes` : null);
          item['orderDate'] = item.orderDate ? moment(item.orderDate).format('DD-MM-YYYY') : null;
          item['startTime'] = item.startTime ? moment(item.startTime).format('hh:mm A') : null;
          item['calendarId'] = item.hygieneCalenderId;
          if (item.status == 'WORKORDER_OPEN') {
            item['workOrderStatus'] = 'Not Started';
            item['workOrderStatusClass'] = "not-started";
          } else if (item.status == 'WORKORDER_CREATED') {
            item['workOrderStatus'] = 'Not Started'
            item['workOrderStatusClass'] = 'not-started';
          } else if (item.status == 'WORKORDER_INPROGRESS') {
            item['workOrderStatus'] = 'Started';
            item['workOrderStatusClass'] = 'started';
          } else if (item.status == 'WORKORDER_HOLD') {
            item['workOrderStatus'] = 'On Hold';
            item['workOrderStatusClass'] = 'onHold';
          } else if (item.status == 'WORKORDER_COMPLETED') {
            item['workOrderStatus'] = 'Completed';
            item['workOrderStatusClass'] = 'completed';
          } else if (item.status == 'WORKORDER_CLOSED') {
            item['workOrderStatus'] = 'Closed';
            item['workOrderStatusClass'] = 'closed';
          } else if (item.status == 'WORKORDER_ESCALATED') {
            item['workOrderStatus'] = 'Escalated';
            item['workOrderStatusClass'] = 'escalated';
          } else if (item.status == 'WORKORDER_REOPEN') {
            item['workOrderStatus'] = 'Not Started';
            item['workOrderStatusClass'] = 'not-started';
          } else if (item.status == 'WORKORDER_DRAFT') {
            item['workOrderStatus'] = 'Drafted';
            item['workOrderStatusClass'] = 'drafted';
          }
          const location = [];
          if (item.buildingName)
            location.push(item.buildingName);
          if (item.floorName)
            location.push(item.floorName);
          if (item.departmentName)
            location.push(item.departmentName);
          if (item.roomName)
            location.push(item.roomName);
          if (this.industryId == '1' && item.bedNo) {
            location.push(item.bedNo);
          }
          else if (this.industryId == '2' && item.seatNo) {
            location.push(item.seatNo);
          }
          // location.reverse();
          item.location = location.join(', ');
        })
      } else {
        this.noResult = true;
      }
      this.loaderService.hide();
    },
      (error) => {
        this.loaderService.hide();
        this.commonService.errorSnackbar(error);
      });
  }
  search() {
    if (this.saveSearch !== '') {
      this.commonService.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.getListing();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection === 'ASC') {
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.getListing();
  }

  // ----------edit---------------------------------------------------------------------------//
  edit(id) {
    this.router.navigate(['admin/hygiene/work-order/edit-work-order/' + id]);
  }

  addWorkOrder(id) {
    this.router.navigate(['admin/hygiene/work-order/add-work-order/' + id]);
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }


  changeStatus(id, status) {
    let workorderStatus = '';
    if (status == 'WORKORDER_HOLD') {
      workorderStatus = 'hold';
    } else if (status == 'WORKORDER_COMPLETED') {
      workorderStatus = 'complete';
    } else if (status == 'WORKORDER_INPROGRESS') {
      workorderStatus = 'inprogress';
    } else if (status == 'WORKORDER_OPEN') {
      workorderStatus = 'open';
    } else if (status == 'WORKORDER_ESCALATED') {
      workorderStatus = 'escalate'
    }else if (status == 'WORKORDER_REOPEN') {
      workorderStatus = 'reopen'
    }
    this.matDialog.open(ConfirmationDialogComponent, {
      data:
      {
        yesBtnName: 'Confirm',
        noBtnName: 'Cancel',
        confirmationMesg: `Are you sure you want to change status to ${workorderStatus} of workorder?`,
        heading: `Confirmation`
      }
    }).afterClosed().subscribe(res => {
      if (res === 1) {
        this.loaderService.show();
        this.workOrderService.updateStatus(id, status).subscribe(res => {
          this.loaderService.hide();
          this.openSuccessDialog(res['description'], 'Success')
        }, err => {
          this.loaderService.hide();
          this.commonService.errorSnackbar(err);
        })
      }
    })

  }


  openSuccessDialog(message, heading) {
    this.matDialog.open(SuccessDialogComponent,
      {
        data:
        {
          yesBtnName: 'OK',
          confirmationMesg: message,
          heading: heading
        }
      }).afterClosed().subscribe(res => {
        this.getListing();
      })
  }

  allCheckbox(event: any) {
    const all = event.target.checked;
    // select all customers
    if (all) {
      this.checkedWorkorders = [];
      this.workOrderList.forEach(element => {
        this.checkedWorkorders.push(element.id);
      });
    } else {
      this.checkedWorkorders = [];
    }
    this.workOrderList.forEach(element => {
      element.selected = all;
    });
    this.workOrderService.selectedWorkOrdersList = [...this.checkedWorkorders];
  }

  checkbox(event: any, i: any) {
    let flag = false;
    for (let j = 0; j < this.pageInfo.size; j++) {
      if (this.checkedWorkorders[j] === i) {
        this.checkedWorkorders.splice(this.checkedWorkorders.indexOf(i), 1);
        const index = this.workOrderList.findIndex(item => item.id === i);
        this.workOrderList[index]['selected'] = false;
        flag = true;
      }
    }
    if (!flag) {
      this.checkedWorkorders.push(i);
      this.workOrderList.forEach(elm => {
        if (elm.id === i) {
          elm['selected'] = true;
        }
      });
    }
    if (this.checkedWorkorders.length == this.workOrderList.length) {
      this.headCheckBox = true;
    } else {
      this.headCheckBox = false;
    }
    this.workOrderService.selectedWorkOrdersList = [...this.checkedWorkorders];
  }

  mapKeysOfFilter() {
    const body = {};
    body['branch'] = this.branchId;
    if (this.startDate)
      body['startDate'] = moment(this.startDate).startOf('day').valueOf();
    if (this.endDate)
      body['endDate'] = moment(this.endDate).endOf('day').valueOf();
    this.statusArray.length > 0 ? body['status'] = this.statusArray : null;
    this.escalateStatusArray.length > 0 ? body['escalateStatus'] = this.escalateStatusArray : null;
    this.buildingArray.length > 0 ? body['building'] = this.buildingArray : null;
    this.floorArray.length > 0 ? body['floor'] = this.floorArray : null;
    this.departmentArray.length > 0 ? body['department'] = this.departmentArray : null;
    this.roomArray.length > 0 ? body['room'] = this.roomArray : null;
    this.seatArray.length > 0 ? body['seat'] = this.seatArray : null;
    this.bedArray.length > 0 ? body['bed'] = this.bedArray : null;
    this.categoryArray.length > 0 ? body['category'] = this.categoryArray : null;
    this.subCategoryArray.length > 0 ? body['subCategory'] = this.subCategoryArray : null;
    return body;
  }

  auditTrail(id) {
    this.router.navigate(['/admin/hygiene/work-order/audit-trail/' + id]);
  }

  deleteWorkOrder(id) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data:
      {
        yesBtnName: 'Confirm',
        noBtnName: 'Cancel',
        confirmationMesg: `Are you sure you want to delete workorder?`,
        heading: `Confirmation`
      }
    }).afterClosed().subscribe((res: any) => {
      if (res === 1) {
        this.loaderService.show();
        this.workOrderService.deleteWorkOrder([id]).subscribe(res => {
          this.loaderService.hide();
          this.openSuccessDialog(res['description'], 'Success');
        })
      }
    })
  }
}
