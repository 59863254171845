import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientComponent } from './patient.component';
import { AddPatientComponent } from './components/patient/add-patient/add-patient.component';
import { PatientSetupCardComponent } from './components/patient-setup-card/patient-setup-card.component';
import { PatientTabComponent } from './components/patient-tab/patient-tab.component';
import { UpdatePatientDetailsApprovalComponent } from './components/update-approval/update-patient-details-approval/update-patient-details-approval.component';
import { PatientConfigurationComponent } from './components/patient-configuration/patient-configuration.component';
import { AddPatientConfigurationComponent } from './components/patient-configuration/add-patient-configuration/add-patient-configuration.component';
// import { UpdatePatientDetailsApprovalComponent } from './components/update-patient-details-approval/update-patient-details-approval/update-patient-details-approval-component';
import { VisitingHoursComponent } from './components/visiting-hours/visiting-hours.component';
import { AddVisitingHoursComponent } from './components/visiting-hours/add-visiting-hours/add-visiting-hours.component';
import { UpdateOnboardApprovalComponent } from './components/onboard-approval/update-onboard-approval/update-onboard-approval.component';
import { ConfigurationFormFieldComponent } from 'src/app/modules/shared/component/configuration-form-field/configuration-form-field.component';
import { AddReasonComponent } from './components/blacklist-reason/add-reason/add-reason.component';
import { BlacklistReasonComponent } from './components/blacklist-reason/blacklist-reason.component';
import { PatientConfigurationFormFieldComponent } from './components/patient/patient-configuration-form-field/patient-configuration-form-field.component';
import { EditConfigurationFormFieldComponent } from '../../../shared/component/configuration-form-field/components/edit-configuration-form-field/edit-configuration-form-field.component';
import { BlackListVisitorApprovalComponent } from './components/blacklist-visitor/blacklist-visitor-approval/blacklist-visitor-approval.component';
import { BlackListVisitorComponent } from './components/blacklist-visitor/blacklist-visitor.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { AddWorkFlowComponent } from './components/workflow/add-workflow/add-work-flow.component';
import { UpdatePatientStatusComponent } from './components/patient/update-patient-status/update-patient-status.component';

const routes: Routes = [
  { path: '', component: PatientSetupCardComponent },
  { path: 'patient', component: PatientComponent },
  { path: 'patient-onboard', component: PatientTabComponent },
  { path: 'patient-onboard/approve/:id', component: UpdateOnboardApprovalComponent },
  { path: 'add-patient', component: AddPatientComponent },
  { path: 'add-patient/:id', component: AddPatientComponent },
  { path: 'update-patient-status/:id', component: UpdatePatientStatusComponent },
  { path: 'update-approval/:id', component: UpdatePatientDetailsApprovalComponent },
  { path: 'patient-configuration', component: PatientConfigurationComponent },
  { path: 'add-patient-configuration', component: AddPatientConfigurationComponent },
  { path: 'add-patient-configuration/:id', component: AddPatientConfigurationComponent },
  { path: 'visiting-hours', component: VisitingHoursComponent },
  { path: 'add-visiting-hours', component: AddVisitingHoursComponent },
  { path: 'add-visiting-hours/:id', component: AddVisitingHoursComponent },
  { path: 'configuration-form-field', component: ConfigurationFormFieldComponent },
  { path: 'patient-configuration-form-field', component: PatientConfigurationFormFieldComponent },
  { path: 'edit-configuration-form-field/:id', component: EditConfigurationFormFieldComponent },
  { path: 'blacklist-reason', component: BlacklistReasonComponent },
  { path: 'add-blacklist-reason', component: AddReasonComponent },
  { path: 'add-blacklist-reason/:id', component: AddReasonComponent },
  { path: 'blacklist-visitor', component: BlackListVisitorComponent },
  { path: 'approve-blacklist-visitor/:id', component: BlackListVisitorApprovalComponent },
  { path: 'workflow', component: WorkflowComponent },
  { path: 'edit-workflow/:id', component: AddWorkFlowComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PatientRoutingModule { }
