import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { environment } from 'src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  profileEdit = new Subject();
  editing = false;
  constructor(private http: HttpClient, private common: CommonServiceService) { }

  getStaffById(staffId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/${staffId}`);
  }

  updateStaff(staffId,body){     
    return this.http.put(`${environment.customerApiUrl}api/v1/staff/${staffId}`,body)
  }

  changePassword(credentials: any) {
    return this.http.post(environment.authApiUrl + 'api/v1/changePassword', credentials);
  }

  changePasswordHC(credentials: any) {
    return this.http.post(environment.authApiUrl + 'api/v1/changePassword/healthCare', credentials);
  }

  getProfileOfficial(employeeId) {
    return this.http.get(environment.apiUrl + 'api/v1/users/profile/official/' + employeeId);
  }

  getOrganization() {
    return this.http.get(environment.apiUrl + 'api/v1/organization');
  }

  getDepartment() {
    return this.http.get(environment.apiUrl + 'api/v1/departments');
  }

  getLocation() {
    return this.http.get(environment.apiUrl + 'api/v1/locations');
  }

  getReportingManager(search) {
    return this.http.get(environment.apiUrl + 'api/v1/users/searchByEmail?searchText=' + search);
  }

  putOfficialDetails(credentials) {
    return this.http.put(environment.apiUrl + 'api/v1/users/profile/oficial', credentials);

  }

  getCountry() {
    return this.http.get(environment.apiUrl + 'api/v1/configs/countries');
  }

  getGender() {
    return this.http.get(environment.apiUrl + 'api/v1/configs/gender');

  }
  getMaritalStatus() {
    return this.http.get(environment.apiUrl + 'api/v1/configs/maritalStatus');

  }
  getEthnicity() {
    return this.http.get(environment.apiUrl + 'api/v1/configs/ethnicity');

  }
  getImmigrationStatus() {
    return this.http.get(environment.apiUrl + 'api/v1/configs/immigrationStatus');

  }

  getProfilePersonal(employeeId) {
    return this.http.get(environment.apiUrl + 'api/v1/users/profile/personal/' + employeeId);
  }

  getProfileSkills(employeeId) {
    return this.http.get(environment.apiUrl + 'api/v1/users/profile/skill/' + employeeId);

  }

  getState(countryId) {
    return this.http.get(environment.apiUrl + 'api/v1/configs/state?countryId=' + countryId);

  }

  getCity(stateId) {
    return this.http.get(environment.apiUrl + 'api/v1/configs/cities?stateId=' + stateId);

  }

  putPersonalDetails(credentials) {
    return this.http.put(environment.apiUrl + 'api/v1/users/profile/personal', credentials);

  }
  putContactDetails(credentials) {
    return this.http.put(environment.apiUrl + 'api/v1/users/profile/contact', credentials);

  }
  putSkillsDetails(employeeId, credentials) {
    return this.http.put(environment.apiUrl + 'api/v1/users/profile/skill/' + employeeId, credentials);
  }

  postUpdatePassword(userId, password) {
    return this.http.post(environment.apiUrl + 'api/v1/users/resetpassword/' + userId, password);
  }
}
