import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.html',
  styleUrls: ['./success-dialog.scss']
})
export class SuccessDialogComponent {

  yesButtonName: string;
  noButtonName: string;
  confirmationMesg: string;
  heading:string;

  // 'tile.view.calendar.yes' | translate

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: any
  ) {
    if (_.isObject(message)) {
      this.yesButtonName = message && message.yesBtnName ? message.yesBtnName : 'Accept';
      this.confirmationMesg = message && message.confirmationMesg ? message.confirmationMesg : '';
      this.heading = message && message.heading ? message.heading : 'Confirmation';
    } else {
      this.yesButtonName = 'Accept';
      this.confirmationMesg = message;
      this.heading = 'Confirm'
    }

  }

  onYesClick(): void {
    this.dialogRef.close(1);
  }
}
