import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { httpHeaderInterceptor } from '../core/interceptors/httpHeader.interceptor';
import { RefreshTokenInterceptor} from '../core/interceptors/auth-response.interceptor';
import { AccountSetupModule } from '../modules/admin/modules/account-setup/account-setup.module';
import { SharedModule } from '../modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { OtpComponent } from '../modules/admin/modules/patient/components/patient/otp/otp.component';
import { PatientModule } from '../modules/admin/modules/patient/patient.module';
import { PrintQrCodeComponent } from '../modules/admin/modules/patient/components/patient/print-qr-code/print-qr-code.component';
import { QrCodeComponent } from '../modules/admin/modules/patient/components/patient/qr-code/qr-code.component';
import { WorkOrderModule } from '../modules/admin/modules/work-order/work-order.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MatDialogModule } from '@angular/material';
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component';
import { SmsUrlComponent } from './sms-url/sms-url.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AsyncPipe } from "@angular/common";
import { environment } from "../config/environments/environment";
import { MessagingService } from '../modules/shared/services/messaging.service';
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
]);
@NgModule({
  declarations: [
    AppComponent,
    SetNewPasswordComponent,
    SmsUrlComponent
  ],

  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AccountSetupModule,
    NgOtpInputModule,
    PatientModule,
    WorkOrderModule,
    FlexLayoutModule,
    FullCalendarModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  entryComponents:[
    OtpComponent,
    PrintQrCodeComponent,
    QrCodeComponent,
  ],
  providers: [
    MessagingService,
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: httpHeaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
