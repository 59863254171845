import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { PatientService } from '../../../service/patient.service';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientListingModel } from '../../../model/patient-list.model';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { PrintQrCodeComponent } from '../print-qr-code/print-qr-code.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { OtpComponent } from '../otp/otp.component';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-list-view',
  templateUrl: './listview.html',
  styleUrls: ['./listview.scss']
})

export class ListViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showMobileTable: boolean;
  @Input() filterValueChange: object;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  public pageInfo: PaginationModel = new PaginationModel();
  element: any = [];
  noResult: boolean = false;
  searchEventSubscription: Subscription;
  saveSearch: any = '';
  message;
  data;
  showPagination = true;
  checkedPatientsId: any = [];
  headCheckBox;
  allChecked = false;
  pages;
  private subscriptions: Subscription[] = [];
  patientCodeArray: any = [];
  departmentArray: any = [];
  branchArray: any = [];
  reportingManagerArray: any = [];
  pageSizeArray = [10, 50, 100];
  patientStatusArray: any = [];
  associatedwith: any = 'user';
  permissionsObject = { read: false, create: false, update: false, delete: false };
  pageSize: number = 10;
  branchIds = localStorage.getItem('branchId') ? JSON.parse(localStorage.getItem('branchId')) : [];
  // departmentIds = JSON.parse(localStorage.getItem('departmentIds'));
  customerId = localStorage.getItem('customerId')
  @HostListener('click') onMouseEnter() {
    this.commonService.sendFilterCloseEvent();
  }
  constructor(
    public router: Router,
    private patientService: PatientService,
    public commonService: CommonServiceService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    private dialog: DialogService,
    private loaderService: LoaderService,
    public matDialog: MatDialog) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
  }
  pageSizeChange(event) {
    if (event.value) {
      this.pageInfo.page = 0;
      this.pageInfo.size = event.value;
      this.listing();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  ngOnChanges(event) {
    this.patientCodeArray = [];
    this.departmentArray = [];
    this.branchArray = [];
    this.reportingManagerArray = [];
    this.patientStatusArray = [];
    if (this.filterValueChange) {
      // this.filterValueChange['PatientCode'].forEach(x => this.patientCodeArray.push(x.id));
      this.filterValueChange['department'].forEach(x => this.departmentArray.push(x.id));
      this.filterValueChange['branch'].forEach(x => this.branchArray.push(x.id));
      // this.filterValueChange['reportingManager'].forEach(x => this.reportingManagerArray.push(x.id));
      this.filterValueChange['approvalStatus'].forEach(x => this.patientStatusArray.push(x.id));
      this.listing();
    }

  }

  ngOnInit() {
    this.permissionsObject = this.commonService.setPermissions('PATIENT');
    this.headCheckBox = false;
    this.subscriptions.push(this.commonService.getSearchEvent().subscribe(() => {
      this.search();
    }));
    this.showPagination = true;
    this.commonService.head = 'name';
    this.listing();
  }

  mapData(item, headers) {
    return {
      heading: headers,
      content: item
    };
  }

  allCheckbox(event: any) {
    const all = event.target.checked;
    // select all patients
    if (all) {
      this.checkedPatientsId = [];
      this.element.forEach(element => {
        this.checkedPatientsId.push(element.id);
      });
    } else {
      this.checkedPatientsId = [];
    }
    this.element.forEach(element => {
      element.selected = all;
    });
  }

  checkbox(event: any, i: any) {
    let flag = false;
    for (let j = 0; j < this.pageInfo.size; j++) {
      if (this.checkedPatientsId[j] === i) {
        this.checkedPatientsId.splice(this.checkedPatientsId.indexOf(i), 1);
        flag = true;
      }
    }
    if (!flag) {
      this.checkedPatientsId.push(i);
    }
    this.element.forEach(elm => {
      if (elm.id === i) {
        elm.selected = true;
      }
    });
  }

  SetPage(event: any) {
    this.pageInfo.page = event.page;
    this.pageInfo.size = event.size;
    this.listing();
  }

  search() {
    if (this.saveSearch !== '') {
      this.commonService.searchText = this.saveSearch;
      this.saveSearch = '';
    }
    this.showPagination = true;
    this.pageInfo.page = 0;
    this.listing();
  }

  sort(colName: string, sortDirection: any) {
    if (this.pageInfo.sortBy === colName) {
      if (sortDirection == 'ASC') {
        this.commonService.head = '';
        this.commonService.sort = 'DESC';
        this.pageInfo.sortBy = '';
        this.pageInfo.sortDir = 'DESC';
      } else {
        this.commonService.sort = sortDirection;
        this.pageInfo.sortDir = sortDirection;
      }
    } else {
      this.commonService.head = colName;
      this.commonService.sort = 'ASC';
      this.pageInfo.sortBy = colName;
      this.pageInfo.sortDir = 'ASC';
    }
    this.listing();
  }

  // --------pagination------------------------------------------------------------------------------//

  mapKeysOfFilter() {
    const body = {};
    this.departmentArray.length > 0 ? body['department'] = this.departmentArray : null;
    this.branchArray.length > 0 ? body['branch'] = this.branchArray : null;
    this.patientStatusArray.length > 0 ? body['approvalStatus'] = this.patientStatusArray : null;
    return body;
  }

  listing() {
    let body;
    this.branchIds = [];
    this.checkedPatientsId = [];
    this.headCheckBox = false;
    localStorage.getItem('branchId') ? this.branchIds.push(localStorage.getItem('branchId')) : [];
    this.branchIds.length ? body = { branch: this.branchIds } : body = {};
    this.loaderService.show();
    this.noResult = true;
    this.patientService.patientList(this.pageInfo, body, this.customerId)
      .subscribe((response: PatientListingModel) => {
        this.loaderService.hide();
        this.pageInfo.TotalRecord = response.data.totalElements;
        this.pageInfo.totalPages = response.data.totalPage;
        if (response.data.payload.length) {
          this.noResult = false;
          this.element = response.data.payload
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
        }
        else {
          this.pageInfo = JSON.parse(JSON.stringify(this.pageInfo));
          this.noResult = true;
        }
      }, error => {
        this.loaderService.hide();
        this.errorSnackBar(error);
      })
  }

  patientReonboard(patient) {
    const dialogRef = this.dialog.openDialog(ConfirmationDialogComponent, 'Are you sure you want to re-onboard this patient?');
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.loaderService.show();
        this.patientService.reOnboardPatient({ id: patient.id, patientId: patient.patientId })
          .subscribe((res: any) => {
            this.loaderService.hide();
            this.listing();
            this.openSnackBar(res.description);
          }, error => {
            this.loaderService.hide();
            this.errorSnackBar(error);
          })
      }
    });
  }

  editPatient(id: any) {
    this.router.navigate(['admin/patient-list/add-patient/' + id]);
  }

  updatePatientStatus(id: any) {
    this.router.navigate(['admin/patient-list/update-patient-status/' + id]);
  }

  reissueQR(id, patientData: any) {
    this.patientService.sendOtpToGetEcard(id)
      .subscribe(res => {
        this.matDialog.open(OtpComponent, { data: patientData })
          .afterClosed()
          .subscribe(response => {
            if (response) {
            } else {

            }
          });
      })
  }

  printQRCode(id, patientData: any) {
    this.matDialog.open(PrintQrCodeComponent, { data: patientData })
      .afterClosed()
      .subscribe(response => {
        if (response) {
        } else {

        }
      });
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    );
  }

  openSnackBar(message: string) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message: message }, type: 'success' },
      ToasterComponent
    );
  }

}
