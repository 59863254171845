import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';
import { CastingData } from 'src/app/config/constant';
@Injectable({
  providedIn: 'root'
})
export class CarousalService {
  constructor(private http: HttpClient,
  ) { }


  setCarousalStorage(propertyName,value){
    let carousalInfo = JSON.parse(localStorage.getItem('carousalInfo'));
    carousalInfo[propertyName] = value;
    localStorage.setItem('carousalInfo', JSON.stringify(carousalInfo));
  }

  get CarousalStorage() {
    return JSON.parse(localStorage.getItem('carousalInfo'))
  }


  getScreenDetail(){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens`);
  }

  getAqiData(body){
    const page = 0;
    const size = 10000;
    const type = body.type;
    const ids = body.ids;
    return this.http.get(`${environment.apiBaseUrl}hygiene/api/v1/work-order/dashboard/aqiList?page=${page}&size=${size}&type=${type}&ids=${ids}&order=DESC`);
  }

  getAtpData(body){
    const page = 0;
    const size = 10000;
    const type = body.type;
    const ids = body.ids;
    return this.http.get(`${environment.apiBaseUrl}hygiene/api/v1/work-order/dashboard/atpList?page=${page}&size=${size}&type=${type}&ids=${ids}&order=DESC`);
  }


//-----------------   weather Api  -------------------

  getLocation(branchId){  
    return this.http.get(`${environment.customerApiUrl}api/v1/branch/address/${branchId}`);
  }

  getLatLong(body){
    body.appid = '439d4b804bc8187953eb36d2a8c26a02'
    return this.http.get(`https://openweathermap.org/data/2.5/find?q=${body.address}&appid=${body.appid}`)
  }

  getWeatherUpdate(body){
    body.appid = '439d4b804bc8187953eb36d2a8c26a02'
    return this.http.get(`https://openweathermap.org/data/2.5/onecall?lat=${body.lat}&lon=${body.lon}&units=${body.units}&appid=${body.appid}`)
  }

  getWeatherAirPollution(body){
    body.appid = '2ae68eb7f9d4b91605f2537f22abc955'
    return this.http.get(`https://api.openweathermap.org/data/2.5/air_pollution?lat=${body.lat}&lon=${body.lon}&appid=${body.appid}`)
  }

  getPrecipitation(body){
    body.appid = '2ae68eb7f9d4b91605f2537f22abc955'
    return this.http.get(`https://api.openweathermap.org/data/2.5/forecast?q=${body.address}&appid=${body.appid}`)
  }

  //-----------------  casting configuration API  -------------------

  checkCastingAccess(){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getScreenAvailability`)
  }

  getCastingList(page,size,searchtext,sortBy,order,status){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getAllCustomersScreenStatus?page=${page}&size=${size}&by=${sortBy}&order=${order}&qry=${searchtext}&status=${status}`)
  }

  getCastingScreenDetail(customerId){
    return this.http.get(`${environment.customerApiUrl}api/v1/castingscreens/getforcustomer?customerId=${customerId}`)
  }

  updateCastingData(body){
    return this.http.put(`${environment.customerApiUrl}api/v1/castingscreens/updatecustomersscreenStatus?customerIds=&customerId=${body.customerId}&isActiveScreen1=${body.isActiveScreen1}&isActiveScreen2=${body.isActiveScreen2}&isActiveScreen3=${body.isActiveScreen3}&isActiveScreen4=${body.isActiveScreen4}&isActiveScreen5=${body.isActiveScreen5}&isActiveScreen6=${body.isActiveScreen6}&isActiveScreen7=${body.isActiveScreen7}&screen1=${body.screen1}&screen2=${body.screen2}&screen3=${body.screen3}&screen4=${body.screen4}&screen7=${body.screen7}&screenStayTime=${CastingData.slideStayTime}`,null);
  }

  updateListToggle(customerId,isCastingAllowed){
    return this.http.put(`${environment.customerApiUrl}api/v1/castingscreens/setcustomerscreenavailability?customerId=${customerId}&isCastingAllowed=${isCastingAllowed}`,null)
  }


}
