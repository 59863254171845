import { Component, OnInit,Input } from '@angular/core';
import { CarousalService } from 'src/app/modules/shared/services/carousal.service';
import * as moment from 'moment';
@Component({
  selector: 'app-slide-weather-updates',
  templateUrl: './slide-weather-updates.component.html',
  styleUrls: ['./slide-weather-updates.component.scss']
})
export class SlideWeatherUpdatesComponent implements OnInit {
  @Input() slideNumber;
  @Input() location;
  @Input() airPollutionData;
  @Input() currentWeatherData:any={};
  @Input() weatherBackgroundImage = 'No_Data.jpg';
  @Input() weatherPrecipitation;
  @Input() hideAddressStrip:Boolean = false;
  constructor( private carousalService: CarousalService,
    ) { }

  ngOnInit() {
  }

}
