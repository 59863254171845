import { Component, Input, OnInit } from '@angular/core';
import { CarousalService } from 'src/app/modules/shared/services/carousal.service';
import * as moment from 'moment';

@Component({
  selector: 'app-slide-air-quality',
  templateUrl: './slide-air-quality.component.html',
  styleUrls: ['./slide-air-quality.component.scss']
})
export class SlideAirQualityComponent implements OnInit {
  @Input() aqiData: any
  @Input() title: any
  @Input() slideNumber;
  @Input() location;
  @Input() aqiUpdateDate:any='';
  @Input() hideAddressStrip:Boolean = false;
  public aqiDataS5 = [{
    title: 'PM 2.5',
    key: 'pm25',
    status:'',
    value: '',
  }, {
    title: 'PM 10',
    key: 'pm10',
    status:  '',
    value:  '',
  }, {
    title: 'CO2',
    key: 'co2',
    status:  '',
    value: '',
  }, {
    title: 'Humidity',
    key: 'humidity',
    status: '',
    value:  '',
  }, {
    title: 'Air Temperature',
    key: 'air-temperature',
    status:  '',
    value: '',
  }];

  constructor( private carousalService: CarousalService,
    ) { }


  ngOnInit() {
  }

}
