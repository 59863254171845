export const CastingData={
    "slideStayTime" : 15000,
    "slideRefreshTime" : 5000,
    "apiKeyForWeatherUpdate" : '439d4b804bc8187953eb36d2a8c26a02',
    "unitsForWeatherUpdate" : 'metric',
    "castImageSize" : 25000
  }
export const messages = {
  otpSuccessAddPatient : "An OTP has been generated and sent to the patient\'s mobile number successfully",
  otpSuccessUpdatePatient : "An OTP has been generated and sent to the patient\'s new mobile number successfully"
}
