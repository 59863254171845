import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { PatientRoutingModule } from './patient-routing.module';
import { PatientComponent } from './patient.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RefreshTokenInterceptor } from 'src/app/core/interceptors/auth-response.interceptor';
import { httpHeaderInterceptor} from 'src/app/core/interceptors/httpHeader.interceptor'
import { MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ListViewComponent } from './components/patient/listview/listview.component';
import { GridViewComponent } from './components/patient/gridview/gridview.component';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSquare, faCheckSquare, faSortAlphaDown, faSort, faSortAlphaUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCheckSquare as farCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterViewComponent } from './components/patient/filter-view/filter-view';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatDialogModule } from '@angular/material';
import { AddPatientComponent } from './components/patient/add-patient/add-patient.component';
import { OtpComponent } from './components/patient/otp/otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { QRCodeModule } from 'angularx-qrcode';
import { PrintQrCodeComponent } from './components/patient/print-qr-code/print-qr-code.component';
import { QrCodeComponent } from './components/patient/qr-code/qr-code.component';
import { PatientSetupCardComponent } from './components/patient-setup-card/patient-setup-card.component';
import { PatientTabComponent } from './components/patient-tab/patient-tab.component';
import { OnboardApprovalComponent } from './components/onboard-approval/onboard-approval.component';

import { OnboardApprovalListViewComponent } from './components/onboard-approval/onboard-approval-listview/onboard-approval-listview.component';
import { OnboardApprovalGridViewComponent } from './components/onboard-approval/onboard-approval-gridview/onboard-approval-gridview.component';

import { UpdateApprovalListViewComponent } from './components/update-approval/update-approval-listview/update-approval-listview.component';
import { UpdateApprovalComponent } from './components/update-approval/update-approval-component';
import { UpdatePatientDetailsApprovalComponent } from './components/update-approval/update-patient-details-approval/update-patient-details-approval.component';
import { PatientConfigurationComponent } from './components/patient-configuration/patient-configuration.component';
import { AddPatientConfigurationComponent } from './components/patient-configuration/add-patient-configuration/add-patient-configuration.component';
import { ConfigListViewComponent } from './components/patient-configuration/config-listview/config-listview.component';
import { AddVisitingHoursComponent } from './components/visiting-hours/add-visiting-hours/add-visiting-hours.component';
import { VisitingHoursComponent } from './components/visiting-hours/visiting-hours.component';
import { VisitingListViewComponent } from './components/visiting-hours/visiting-listview/visiting-listview.component';
import { UpdateOnboardApprovalComponent } from './components/onboard-approval/update-onboard-approval/update-onboard-approval.component';
import { ConfigGridViewComponent } from './components/patient-configuration/config-gridview/config-gridview.component';
import { ConfigFilterViewComponent } from './components/patient-configuration/config-filter-view/config-filter-view';
import { OnboardApprovalFilterViewComponent } from './components/onboard-approval/onboard-approval-filter-view/onboard-approval-filter-view';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AddReasonComponent } from './components/blacklist-reason/add-reason/add-reason.component';
import { ReasonListViewComponent } from './components/blacklist-reason/reason-listview/reason-listview.component';
import { BlacklistReasonComponent } from './components/blacklist-reason/blacklist-reason.component';
import { PatientConfigurationFormFieldComponent } from './components/patient/patient-configuration-form-field/patient-configuration-form-field.component';
import { BlackListVisitorApprovalComponent } from './components/blacklist-visitor/blacklist-visitor-approval/blacklist-visitor-approval.component';
import { BlackListVisitorApprovalListView } from './components/blacklist-visitor/blacklist-visitor-approval-listview/blacklist-visitor-approval-listview.component';
import { BlackListVisitorComponent } from './components/blacklist-visitor/blacklist-visitor.component';
import { BlackListVisitorListViewComponent } from './components/blacklist-visitor/blacklist-visitor-listview/blacklist-visitor-listview.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { AddWorkFlowComponent } from './components/workflow/add-workflow/add-work-flow.component';
import { WorkflowListViewComponent } from './components/workflow/workflow-listview/workflow-listview.component';
import { UpdatePatientStatusComponent } from './components/patient/update-patient-status/update-patient-status.component'
@NgModule({
  declarations: [
    PatientComponent,
    ListViewComponent,
    GridViewComponent,
    ListViewComponent,
    GridViewComponent,
    FilterViewComponent,
    AddPatientComponent,
    OtpComponent,
    PrintQrCodeComponent,
    QrCodeComponent,
    PatientSetupCardComponent,
    PatientTabComponent,
    UpdateApprovalComponent,
    OnboardApprovalComponent,
    UpdatePatientDetailsApprovalComponent,
    OnboardApprovalListViewComponent,
    OnboardApprovalGridViewComponent,
    UpdateApprovalListViewComponent,
    PatientConfigurationComponent,
    AddPatientConfigurationComponent,
    ConfigListViewComponent,
    AddVisitingHoursComponent,
    VisitingHoursComponent,
    VisitingListViewComponent,
    UpdateOnboardApprovalComponent,
    ConfigGridViewComponent,
    ConfigFilterViewComponent,
    OnboardApprovalFilterViewComponent,
    BlacklistReasonComponent,
    AddReasonComponent,
    ReasonListViewComponent,
    PatientConfigurationFormFieldComponent,
    BlackListVisitorApprovalComponent,
    BlackListVisitorApprovalListView,
    BlackListVisitorComponent,
    BlackListVisitorListViewComponent,
    WorkflowComponent,
    AddWorkFlowComponent,
    WorkflowListViewComponent,
    UpdatePatientStatusComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    PatientRoutingModule,
    MDBBootstrapModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatExpansionModule,
    MatInputModule,
    MatDividerModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    HttpClientModule,
    SharedModule,
    MatListModule,
    MatIconModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    NgOtpInputModule,
    PatientRoutingModule,
    QRCodeModule,
    NgxQRCodeModule,
    NgxMatIntlTelInputModule
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: httpHeaderInterceptor, multi: true }],
  bootstrap: [PatientComponent
  ]
})

export class PatientModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faSquare, faSortAlphaDown, faTimesCircle, faCheckSquare, faSortAlphaUp,
      faSort, farSquare, farCheckSquare, faStackOverflow, faGithub, faMedium);
  }
}
