import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { environment } from 'src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterPicklistService {

  constructor(private http: HttpClient, private common: CommonServiceService, private loader: LoaderService) { }

  departmentList(searchText: any, pageInfo: PaginationModel, body) {
    let params = new HttpParams();
    if (body.branchIds) {
      params = params.append('branchIds', body.branchIds);
    }
    if (body.buildingIds && body.buildingIds.length) {
      const buildingId = body.buildingIds.join(',');
      params = params.append('buildingIds', buildingId);
    }
    if (body.floorIds && body.floorIds.length) {
      const floorId = body.floorIds.join(',');
      params = params.append('floorIds', floorId);
    }
    return this.http.get(`${environment.customerApiUrl}api/v1/department/department-picklist?page=${pageInfo.page}&searchText=${encodeURIComponent(searchText.toLowerCase())}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`, { params: params });
  }

  branchList(searchText: any, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/branch/branchListing?page=${pageInfo.page}&searchText=${encodeURIComponent(searchText.toLowerCase())}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  buildingList(searchText: any, pageInfo: PaginationModel, body) {
    let params = new HttpParams();
    params = params.append('branchIds', body.branchIds)
    return this.http.get(`${environment.customerApiUrl}api/v1/building/building-picklist?page=${pageInfo.page}&searchText=${encodeURIComponent(searchText.toLowerCase())}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`, { params: params });
  }

  floorList(searchText: any, pageInfo: PaginationModel, body) {
    let params = new HttpParams();
    if (body.branchIds) {
      params = params.append('branchIds', body.branchIds);
    }
    if (body.buildingIds && body.buildingIds.length) {
      const buildingId = body.buildingIds.join(',');
      params = params.append('buildingIds', buildingId);
    }
    return this.http.get(`${environment.customerApiUrl}api/v1/floor/floor-picklist?page=${pageInfo.page}&searchText=${encodeURIComponent(searchText.toLowerCase())}&size=${pageInfo.size}`, { params: params });
  }

  roomList(searchText: any, pageInfo: PaginationModel, body) {
    let params = new HttpParams();
    if (body.branchId) {
      params = params.append('branchIds', body.branchIds);
    }
    if (body.buildingIds && body.buildingIds.length) {
      const buildingId = body.buildingIds.join(',');
      params = params.append('buildingIds', buildingId);
    }
    if (body.floorIds && body.floorIds.length) {
      const floorId = body.floorIds.join(',');
      params = params.append('floorIds', floorId);
    }
    if (body.departmentIds && body.departmentIds.length) {
      const departmentId = body.departmentIds.join(',');
      params = params.append('departmentIds', departmentId);
    }
    return this.http.get(`${environment.customerApiUrl}api/v1/room/room-picklist?page=${pageInfo.page}&searchText=${encodeURIComponent(searchText.toLowerCase())}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`, { params: params });
  }

  getSeat(searchText, pageInfo: PaginationModel, filterData) {
    let params = new HttpParams();
    if (filterData.branchIds) {
      // const branchIds = filterData.branchIds.join(',');
      params = params.append('branch', filterData.branchIds)
    }
    if (filterData.buildingIds) {
      const buildingIds = filterData.buildingIds.join(',');
      params = params.append('building', buildingIds)
    }
    if (filterData.floorIds) {
      const floorIds = filterData.floorIds.join(',');
      params = params.append('floor', floorIds);
    }
    if (filterData.departmentIds) {
      const departmentIds = filterData.departmentIds.join(',');
      params = params.append('department', departmentIds);
    }
    if (filterData.roomIds) {
      const roomIds = filterData.roomIds.join(',');
      params = params.append('room', roomIds);
    }
    return this.http.get(`${environment.apiBaseUrl}inventory/api/v1/inventory/seat/list?page=${pageInfo.page}&searchText=${encodeURIComponent(searchText.toLowerCase())}&size=${pageInfo.size}&by=${pageInfo.sortBy}&order=${pageInfo.sortDir}`, { params: params });
  }

  getBed(searchText, customerId: any, pageInfo: PaginationModel, filterData) {
    let params = new HttpParams();
    if (filterData.branchIds) {
      // const branchIds = filterData.branchIds.join(',');
      params = params.append('branch', filterData.branchIds)
    }
    if (filterData.buildingIds) {
      const buildingIds = filterData.buildingIds.join(',');
      params = params.append('building', buildingIds)
    }
    if (filterData.floorIds) {
      const floorIds = filterData.floorIds.join(',');
      params = params.append('floor', floorIds);
    }
    if (filterData.departmentIds) {
      const departmentIds = filterData.departmentIds.join(',');
      params = params.append('department', departmentIds);
    }
    if (filterData.roomIds) {
      const roomIds = filterData.roomIds.join(',');
      params = params.append('room', roomIds);
    }
    return this.http.get(`${environment.customerApiUrl}api/v1/seatBed/seatBedListing?customerId=${customerId}&page=${pageInfo.page}&searchText=${encodeURIComponent(searchText.toLowerCase())}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  getCategoryList(searchText, pageInfo, isSubCategory, categoryArray, status?) {
    let params = new HttpParams();
    if (isSubCategory && categoryArray.length) {
      const categoryIds = categoryArray.join(',');
      params = params.append('parentId', categoryIds);
    }
    let url = `${environment.apiBaseUrl}hygiene/api/v1/hygiene-categories?subcat=${isSubCategory}&page=${pageInfo.page}&size=${pageInfo.size}&by=${pageInfo.sortBy}&order=${pageInfo.sortDir}&rec=${pageInfo.rec}&searchText=${encodeURIComponent(searchText.toLowerCase())}&active=${status}`;
    return this.http.get(url, { params: params });
  }

  getMeetingRoomType() {
    return this.http.get(`${environment.apiBaseUrl}inventory/api/v1/meetingRoomType/web`);
  }
}

