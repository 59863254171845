export const environment = {
  production: true,
  apiBaseUrl: 'https://api-hcprod.caleedo.co/',
  apiUrl: 'http://182.76.226.218:8082/',
  apiUrlV1: 'http://182.76.226.218:8082/api/v1/',
  // rolBaseUrl: 'https://api-test.caleedo.xyz/api/v1',
  authApiUrl: 'https://api-hcprod.caleedo.co/authhc/',
  newApiUrl: 'https://api-hcprod.caleedo.co/authhc/api/v1/',
  customerApiUrl: 'https://api-hcprod.caleedo.co/cobhc/',
  patientApiUrl: 'https://api-hcprod.caleedo.co/pvhc/',
  staffApiUrl: 'https://api.caleedo.co/api/v1/skills',
  notificationApiUrl: 'https://api-hcprod.caleedo.co/tpihc/',
  tpBaseUrl: 'https://api-hcprod.caleedo.co/tpihc/',
  visitorUrl: 'https://api.caleedo.co/visitor/api/v1/',
  ravenBaseUrl : 'https://clean.reaventech.com/',
  firebase: {
      apiKey: "AIzaSyCyzemDYeb6Cx-YKkuQmNDQ9xW4Q95_iEY",
      authDomain: "caleedo-4a17d.firebaseapp.com",
      projectId: "caleedo-4a17d",
      storageBucket: "caleedo-4a17d.appspot.com",
      messagingSenderId: "662973170467",
      appId: "1:662973170467:web:c153194f4004d00714c296",
      measurementId: "G-ZNRQKRF3SY"
    },
};