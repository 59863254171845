import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { CommonServiceService } from '../common-service.service';
import { HttpHandlerProvider } from '../http-handler.provider';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    public commonService: CommonServiceService,
    public httpHandlerProviderService: HttpHandlerProvider
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem('isLogged')) {
      this.httpHandlerProviderService.isLogged = true;
      if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('HEALTH_DASHBOARD')) {
        this.router.navigate(['/admin/healthcare-dashboard']);
      }
      else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('VISITOR_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/visitor']);
      } else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('SEAT_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/seat']);
      }
      else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('MEET_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/meet']);
      } else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('HYGIENE_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/hygiene']);
      } else if (route.routeConfig.path === 'dashboard' && this.commonService.setPermissions('ENTERPRISE_DASHBOARD')) {
        this.router.navigate(['/admin/enterprise-dashboard/visitor']);
      }
      return true;
    } else {
      if (route.routeConfig.path == '') {
        this.router.navigate(['/auth/login']);
        return false;
      } else {
        if (route.routeConfig.path == 'resetPasswordLinkSuccess') {
          if (localStorage.getItem('LinkSent') == 'true') {
            return true;
          }
        }
        if (route.routeConfig.path == 'urlExpired') {
          if (localStorage.getItem('UrlExpired') == 'true') {
            return true;
          }
        }
        this.router.navigate(['/auth/login']);
        return false;
      }
    }
    return true;
  }
}
