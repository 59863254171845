import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  ipAddress: { ip: string; };
  constructor(private http: HttpClient) { }
  get profileImg() {
    return localStorage.getItem('profileImg')
  }
    
  login(credentials) {
    return this.http.post(environment.newApiUrl + 'login', credentials)
  }
  refreshToken(token) {
    return this.http.post(environment.newApiUrl + 'refreshToken' , token)
  }
  getUserInfo(email) {
    return this.http.get(environment.apiUrl + 'api/v1/users/getAccountInformation?email=' + email);
  }
  updateFirstLogin(userId, applicationType,body){
    return this.http.put(`${environment.authApiUrl}api/v1/users/updateFirstLogin/${userId}?applicationType=${applicationType}`,body)
  }
  getCustomerProfileImage(){
    return this.http.get(`${environment.customerApiUrl}api/v1/customers/getCustomerProfileImage`);
  }
  getImage(key) {
    return this.http.get(`${environment.notificationApiUrl}api/v1/images/getImage?key=${key}`, { responseType: 'blob', observe: 'response' });
  }
}
