import { Component,  OnInit, } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { PatientService } from '../../../service/patient.service';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PatientListingModel, PatientStatusModel } from '../../../model/patient-list.model';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { PrintQrCodeComponent } from '../print-qr-code/print-qr-code.component';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { OtpComponent } from '../otp/otp.component';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';
import { common } from 'src/app/config/enum';
import { PicklistService } from 'src/app/modules/shared/services/picklist.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';
@Component({
  selector: 'app-update-patient-status',
  templateUrl: './update-patient-status.component.html',
  styleUrls: ['./update-patient-status.component.scss']
})
export class UpdatePatientStatusComponent implements OnInit {
  isActive=true
  patientId:any;
  patientData:any;
  patientStatusList=[];
  newStatus:string;
  updateStatusForm: FormGroup;
  // attachmentType: any;
  attachmentList=[];
  editAttachmentList=[];
  attachmentFormData=[];
  editAttachmentFormData=[];
  editAttachmentLink=[];
  showImgModal=false;
  modelImg='';
  userId:any;
  userName:string;
  editStatusText:string;
  selectedIndex=-1;
  canApproveFlag=0;
  admittingDocUserId=null;
  roleCode='';
  userStaffId = '';
  constructor(public activatedRoute: ActivatedRoute, 
    private loaderService: LoaderService,
    public snackBar: MatSnackBar,
    public toastService: ToastService,
    public fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private pickListService: PicklistService,
    private patientService: PatientService,
    private router: Router,
    private dialogService: DialogService,
    private matDialog: MatDialog) {
  }
  ngOnInit() {
    this.getParams();
    this.userId=localStorage.getItem('LogoutId');
    this.userName=localStorage.getItem('userName');
    console.log(localStorage.getItem('userName'))
    this.getStaffByUserId();
    this.roleCode=localStorage.getItem('roleCode');
    this.loadData();
  }

  getParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        console.log(params)
        this.patientId = params.id;
        this.patientService.getPatientDetails(this.patientId).subscribe((res) => {
          console.log('patientData')
          this.patientData=res['data'];
          this.getAdmittingDocUserId(this.patientData.doctorId)
          console.log(res)
        });
      }
    });
  }

  loadData(){
    this.updateStatusForm = this.fb.group({
      status: ['', [Validators.required]],
    });
    this.attachmentList=[];
    this.editAttachmentList=[];
    this.attachmentFormData=[];
    this.editAttachmentFormData=[];
    this.editAttachmentLink=[];
    this.editStatusText='';
    this.patientStatusList=[];
    this.getPatientStatusList();
  }

  getAdmittingDocUserId(doctorId)
  {
    this.patientService.getUserByStaffId(doctorId)
    .subscribe((res) => {
      console.log(res)
      this.admittingDocUserId=res['data'].userId;
      if(this.admittingDocUserId==this.userId)
      {
        this.canApproveFlag=1;
      }
    }, error => {
      this.errorSnackBar(error);
      console.log(error)
    });
  }

  getStaffByUserId()
  {
    this.patientService.getStaffByUserId(this.userId)
    .subscribe((res) => {
      console.log('getstaffbyuserid '+this.userId)
      console.log(res)
      this.userStaffId=res['data'].id;
    }, error => {
      this.errorSnackBar(error);
      console.log(error)
    });
  }

  getPatientStatusList(){
    this.loaderService.show();
    this.patientService
    .getPatientStatusDetails(this.patientId)
    .subscribe(async (res) => {
      console.log(res)
      console.log('getPatientStatusDetails')
      this.loaderService.hide();
      let patientStatusList = res['data'];
      this.patientStatusList=[];
      let patientStatusListKeys = [];
      for(var key in patientStatusList)
        patientStatusListKeys.push(key);

      patientStatusListKeys.sort();
      patientStatusListKeys.reverse();

      for (let index = 0; index < patientStatusListKeys.length; index++) {
        if(patientStatusList[patientStatusListKeys[index]].isDeleted===0)
        {
          this.patientStatusList.push(patientStatusList[patientStatusListKeys[index]])
          this.patientStatusList[this.patientStatusList.length-1].timestamp = new Date(patientStatusListKeys[index]*1000);
          this.patientStatusList[this.patientStatusList.length-1].timestampKey = patientStatusListKeys[index];
          this.patientStatusList[this.patientStatusList.length-1].attachmentLinks=[];
          for(let i=0; i<this.patientStatusList[this.patientStatusList.length-1].attachments.length;i++)
          {
            let key = this.patientStatusList[this.patientStatusList.length-1].attachments[i];
            let extension = key.match(/[^.]+$/);
            if(extension[0]=='pdf')
              await this.getPdf(key, this.patientStatusList.length-1);
            else
              await this.getImage(key, this.patientStatusList.length-1);
          }
        }
      }
      console.log(this.patientStatusList)
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
      console.log(error)
    });
  }

  async callSavePatientStatus(){
    if(this.attachmentList.length>0)
    {
      var index=1;
      await this.attachmentList.forEach(async attachment => {
        this.loaderService.show();
        console.log('uploading files')
        console.log(attachment)
        let formData: FormData = new FormData();
        formData.append('file', attachment['file']);
        await this.pickListService.uploadFile(formData).subscribe(res => {
          console.log(res)
          this.attachmentFormData.push(res);
          this.loaderService.hide();
          this.successSnackBar("file "+index+" uploaded.");
          if(index===this.attachmentList.length) {
            console.log('calling savePatientStatus function')
            this.savePatientStatus();
          }
          index++;
        });
      });
    }
    else {
      this.savePatientStatus();
    }
  }

  savePatientStatus(){
    this.loaderService.show();
    let patientStatusUpdateDetailsDto={
      attachments: this.attachmentFormData,
      patientId:this.patientId,
      staffId:this.userStaffId,
      staffName:this.userName,
      statusText:this.updateStatusForm.value.status,
      isApproved:0,
      approvedBy:null,
      approvalDateTime:null,
      isDeleted:0
    }
    if(this.canApproveFlag===1) {
      patientStatusUpdateDetailsDto.isApproved=1
      patientStatusUpdateDetailsDto.approvedBy=this.userId
      patientStatusUpdateDetailsDto.approvalDateTime=Math.floor(new Date().getTime()/1000)
    }

    let formData={
      patientStatusUpdateDetailsDto:patientStatusUpdateDetailsDto,
      timestamp:Math.floor(new Date().getTime()/1000),
      
    }
    console.log(formData);
    this.patientService.updatePatientStatusDetails(formData, this.patientId)
    .subscribe((res) => {
      console.log(res)
      this.loaderService.hide();
      this.successSnackBar(res['description']);
      this.loadData();
      // this.router.navigate(['admin/patient-list']);
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
      console.log(error)
    });
  }
  
  async callUpdatePatientStatus(i){
    if(this.editAttachmentList.length>0)
    {
      var index=1;
      await this.editAttachmentList.forEach(async attachment => {
        this.loaderService.show();
        console.log('uploading files')
        console.log(attachment)
        let formData: FormData = new FormData();
        formData.append('file', attachment['file']);
        await this.pickListService.uploadFile(formData).subscribe(res => {
          console.log(res)
          this.editAttachmentFormData.push(res);
          this.loaderService.hide();
          this.successSnackBar("file "+index+" uploaded.");
          if(index===this.editAttachmentList.length) {
            console.log(this.editAttachmentList)
            console.log(this.editAttachmentFormData)
            console.log('calling updatePatientStatus function')
            this.updatePatientStatus(i);
          }
          index++;
        });
      });
    }
    else {
      this.updatePatientStatus(i);
    }
  }

  updatePatientStatus(i){
    this.loaderService.show();
    let patientStatusUpdateDetailsDto={
      attachments: this.editAttachmentFormData,
      patientId:this.patientId,
      staffId:this.userStaffId,
      staffName:this.userName,
      statusText:this.editStatusText,
      isApproved:0,
      isDeleted:0,
      approvedBy:null,
      approvalDateTime:null
    }
    if(this.canApproveFlag===1) {
      patientStatusUpdateDetailsDto.isApproved=1
      patientStatusUpdateDetailsDto.approvedBy=this.userId
      patientStatusUpdateDetailsDto.approvalDateTime=Math.floor(new Date().getTime()/1000)
    }

    let formData={
      patientStatusUpdateDetailsDto:patientStatusUpdateDetailsDto,
      timestamp:this.patientStatusList[i].timestampKey,
      
    }
    console.log(formData);
    this.patientService.updatePatientStatusDetails(formData, this.patientId)
    .subscribe((res) => {
      console.log(res)
      this.loaderService.hide();
      if(res['description'].indexOf("Successfully Added")>-1)
        this.successSnackBar("Successfully Updated");
      this.loadData();
      // this.router.navigate(['admin/patient-list']);
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
      console.log(error)
    });
  }

  approvePatientStatus(i){
    this.loaderService.show();
    let patientStatusUpdateDetailsDto={
      attachments: this.patientStatusList[i].attachments,
      patientId:this.patientStatusList[i].patientId,
      staffId:this.patientStatusList[i].staffId,
      staffName:this.patientStatusList[i].staffName,
      statusText:this.patientStatusList[i].statusText,
      isApproved:1,
      approvedBy:this.userId,
      approvalDateTime:Math.floor(new Date().getTime()/1000),
      isDeleted:0
    }
    let formData={
      patientStatusUpdateDetailsDto:patientStatusUpdateDetailsDto,
      timestamp:this.patientStatusList[i].timestampKey,
      
    }
    console.log(formData);
    this.patientService.updatePatientStatusDetails(formData, this.patientId)
    .subscribe((res) => {
      console.log(res)
      this.loaderService.hide();
      if(res['description'].indexOf("Successfully Added")>-1)
        this.successSnackBar("Successfully Approved");
      this.loadData();
      // this.router.navigate(['admin/patient-list']);
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
      console.log(error)
    });
  }

  deletePatientStatus(i){
    this.loaderService.show();
    let patientStatusUpdateDetailsDto={
      attachments: this.patientStatusList[i].attachments,
      patientId:this.patientStatusList[i].patientId,
      staffId:this.patientStatusList[i].staffId,
      staffName:this.patientStatusList[i].staffName,
      statusText:this.patientStatusList[i].statusText,
      isApproved:0,
      approvedBy:null,
      approvalDateTime:null,
      isDeleted:1,
    }
    let formData={
      patientStatusUpdateDetailsDto:patientStatusUpdateDetailsDto,
      timestamp:this.patientStatusList[i].timestampKey,
    }
    console.log(formData);
    this.patientService.updatePatientStatusDetails(formData, this.patientId)
    .subscribe((res) => {
      console.log(res)
      this.loaderService.hide();
      if(res['description'].indexOf("Successfully Added")>-1)
        this.successSnackBar("Successfully Deleted");
      this.loadData();
      // this.router.navigate(['admin/patient-list']);
    }, error => {
      this.loaderService.hide();
      this.errorSnackBar(error);
      console.log(error)
    });
  }
  
  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  fileChange(event: any) {
    if (event[0] != null) {
      let regex = new RegExp('[^.]+$');
      let extension = event[0].name.match(regex);
      if (event[0].size / 1000 < common.ImageSize) {
        if (extension[0] == 'png' || extension[0] == 'jpg' || extension[0] == 'jpeg' || extension[0] == 'pdf') {
          let attachment = {};
          attachment['name'] = event[0].name;
          attachment['file'] = event[0];
          attachment['type'] = extension[0]
            var reader = new FileReader();
            reader.onload = (event) => {
              this.attachmentList[this.attachmentList.length-1]['path'] = event['target']['result'];
            }
            this.attachmentList.push(attachment);
            reader.readAsDataURL(event[0]);
        }
        else {
          this.matDialog.open(SuccessDialogComponent, {
            data: {
              yesBtnName: 'OK',
              confirmationMesg: 'Error shown regarding the image extension you have uploaded. Kindly select png/jpg/jpeg/pdf file format',
              heading: 'Error'
            }
          }).afterClosed().subscribe(res => {
          });
        }
      } else {
        this.matDialog.open(SuccessDialogComponent, {
          data: {
            yesBtnName: 'OK',
            confirmationMesg: 'Error shown regarding the file size you have uploaded. Kindly make the image size as 5MB',
            heading: 'Error'
          }
        }).afterClosed().subscribe(res => {
        });
      }
    }
  }

  editFileChange(event: any) {
    if (event[0] != null) {
      let regex = new RegExp('[^.]+$');
      let extension = event[0].name.match(regex);
      if (event[0].size / 1000 < common.ImageSize) {
        if (extension[0] == 'png' || extension[0] == 'jpg' || extension[0] == 'jpeg' || extension[0] == 'pdf') {
          let attachment = {};
          attachment['name'] = event[0].name;
          attachment['file'] = event[0]
            var reader = new FileReader();
            reader.onload = (event) => {
              console.log(this.editAttachmentList[this.editAttachmentList.length-1])
              this.editAttachmentList[this.editAttachmentList.length-1]['path'] = event['target']['result'];
            }
            this.editAttachmentList.push(attachment);
            reader.readAsDataURL(event[0]);
        }
        else {
          this.matDialog.open(SuccessDialogComponent, {
            data: {
              yesBtnName: 'OK',
              confirmationMesg: 'Error shown regarding the image extension you have uploaded. Kindly select png/jpg/jpeg/pdf file format',
              heading: 'Error'
            }
          }).afterClosed().subscribe(res => {
          });
        }
      } else {
        this.matDialog.open(SuccessDialogComponent, {
          data: {
            yesBtnName: 'OK',
            confirmationMesg: 'Error shown regarding the file size you have uploaded. Kindly make the image size as 5MB',
            heading: 'Error'
          }
        }).afterClosed().subscribe(res => {
        });
      }
    }
  }

  removeAttachmentListItem(index) {
    this.attachmentList.splice(index,1);
  }

  removeEditAttachmentListItem(index) {
    this.editAttachmentList.splice(index,1);
  }

  private async getImage(key, index) {
    await this.pickListService.getUploadedFile(key).subscribe((res: any) => {
      console.log('getImage response')
      console.log(res)
      console.log(res.url)
      let filename = res.url;
      // this.patchImage(res.body, index);
      if(index>=-1) {          
        this.patientStatusList[index].attachmentLinks.push(filename);
        console.log(this.patientStatusList[index]);
      }
    }, error => {
      this.errorSnackBar(error);
      
    })
  }

  private async getPdf(key, index) {
    await this.pickListService.getPdf(key).subscribe((res: any) => {
      // console.log(res)
      // console.log(res.url)
      let filename = res.url;
      // this.patchImage(res.body, index);
      if(index>=-1) {          
        this.patientStatusList[index].attachmentLinks.push(filename);
        console.log(this.patientStatusList[index]);
      }
    }, error => {
      this.errorSnackBar(error);
      
    })
}

  // patchImage(image: Blob, index) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(image);
  //   reader.onload = (event) => {
  //     if(index>=-1) {
  //       this.patientStatusList[index].attachmentLinks.push(event['target']['result'])
  //     }
  //   }
  // }

  openImg(link) {
    console.log('openimg called')
    this.showImgModal=true;
    this.modelImg=link;
  }

  closeImgModel() {
    console.log('closeImgModel called')
    this.showImgModal=false;
    this.modelImg='';
  }

  // downloadFile() {
  //   const a = document.createElement('a');
  //   const blob = new Blob([this.attachment], { type: 'text/' + this.attachmentType });
  //   // const a = document.createElement('a');
  //   a.href = window.URL.createObjectURL(blob);
  //   a.download = this.attachment['name'];
  //   document.body.appendChild(a);
  //   a.click();
  //   // }
  // }

  edit(i) {
    this.patientStatusList[i].editable=true;
    this.editStatusText = this.patientStatusList[i].statusText;
    this.selectedIndex=i;
    this.editAttachmentFormData = this.patientStatusList[i].attachments;
    this.editAttachmentLink = this.patientStatusList[i].attachmentLinks;
    this.editAttachmentList=[];
    for(let m=0;m<this.patientStatusList.length;m++) {
      if(m!=i) {
        this.patientStatusList[m].editable=false;
      }
    }
  }

  deleteAttachment(linkindex) {
    this.editAttachmentFormData.splice(linkindex,1);
    console.log(this.editAttachmentFormData);
    this.editAttachmentLink.splice(linkindex,1);
    console.log('after deleting attacment');
    console.log(this.patientStatusList[this.selectedIndex].attachments);
    
  }

  approve(i)
  {
    this.matDialog.open(ConfirmationDialogComponent, {
      data:
      {
        yesBtnName: 'Confirm',
        noBtnName: 'Cancel',
        confirmationMesg: `Are you sure you want to approve this status summary?`,
        heading: 'Confirm Approve'
      }
    }).afterClosed().subscribe(res => {
      if (res === 1) {
        this.approvePatientStatus(i);
      }
    })
  }

  canceledit(i) {
    this.patientStatusList[i].editable=false;
    this.editAttachmentFormData=[];
    this.editAttachmentLink=[];
    this.editStatusText='';
    this.selectedIndex=-1;
    this.editAttachmentList=[];
    console.log('cancel edit')
    console.log('attachment links')
    console.log(this.patientStatusList[i])
  }

  delete(i) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data:
      {
        yesBtnName: 'Confirm',
        noBtnName: 'Cancel',
        confirmationMesg: `Are you sure you want to remove this status summary?`,
        heading: 'Confirm Delete'
      }
    }).afterClosed().subscribe(res => {
      if (res === 1) {
        this.deletePatientStatus(i)
      }
    })
  }

  isImage(link) {
    if(link.split('.').pop() === 'png' || link.split('.').pop() === 'jpg' || link.split('.').pop() === 'jpeg'){
      return true;
    }
    else {
      return false;
    }
  }

  successSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }
  

}
