import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-slider-header',
  templateUrl: './slider-header.component.html',
  styleUrls: ['./slider-header.component.scss']
})
export class SliderHeaderComponent implements OnInit {
  public now: Date = new Date();
  public headerTodaysTime
  public headerTodaysDate
  public customerLogo = localStorage.getItem('logoImg')

  constructor() {
    setInterval(() => {
      this.now = new Date();
      this.headerTodaysTime = moment(new Date().getTime()).format('h:mm 	A')
      this.headerTodaysDate = moment(new Date().getTime()).format('ddd, D MMM')
    }, 1);
   }

  ngOnInit() {
  }

}
