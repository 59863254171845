import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { environment } from 'src/app/config/environments/environment';
import { Subject } from 'rxjs';
import { MatTabBody } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  isPrinting: boolean = false;
  unapproveOnboardPatientListing = new Subject<Boolean>();
  unapproveOnboardPatientListObservable = this.unapproveOnboardPatientListing.asObservable();

  constructor(private http: HttpClient, private common: CommonServiceService, private loader: LoaderService) { }

  patientList(pageInfo: PaginationModel, body, customerId) {
    return this.http.post(`${environment.patientApiUrl}api/v1/patient/patientListing?flag=None&customerId=${customerId}&page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`, body);
  }

  getPatientDetails(patientId) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/viewDetails/${patientId}`);
  }

  getPatientStatusDetails(patientId) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/getPatientStatusDetails/${patientId}`);
  }

  // removeUpdateStatus(patientId,staffId,timestamp) {
  //   return this.http.post(`${environment.patientApiUrl}api/v1/patient/removeUpdateStatus/${patientId}`,{staffId:staffId,timestamp:timestamp});
  // }

  updatePatientStatusDetails(body,patientId) {
    return this.http.post(`${environment.patientApiUrl}api/v1/patient/updatePatientStatusDetails/${patientId}`, body);
  }

  addPatientByStaff(body) {
    return this.http.post(`${environment.patientApiUrl}api/v1/patient/addPatientByStaff`, body);
  }

  updatePatientRequest(pageInfo: PaginationModel, branchId, customerId) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/updateRequest?branchId=${branchId}&customerId=${customerId}&page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  addPatient(body, params) {
    let param = new HttpParams();
    if (params && params['rejectedPatientId']) {
      param = param.append('rejectedPatientId', params.rejectedPatientId)
    }
    return this.http.post(`${environment.patientApiUrl}api/v1/patient/addPatientByStaff`, body, { params });
  }

  updatePatient(body, patientId) {
    return this.http.put(`${environment.patientApiUrl}api/v1/patient/${patientId}`, body);
  }

  printQRCode(patientId, type = '1') {
    return this.http.get(`${environment.patientApiUrl}api/v1/visitors/printCode?id=${patientId}&type=${type}`);
  }

  getImage(key) {
    return this.http.get(`${environment.notificationApiUrl}api/v1/images/getImage?key=${key}`, { responseType: 'blob' });
  }

  QRCode(id, attendantKeyKey?, visitorKey?, type = '1') {
    if (attendantKeyKey && visitorKey)
      return this.http.get(`${environment.patientApiUrl}api/v1/visitors/qrCode?attendantKeyKey=${attendantKeyKey}&id=${id}&type=${type}&visitorKey=${visitorKey}`);
    else if (attendantKeyKey)
      return this.http.get(`${environment.patientApiUrl}api/v1/visitors/qrCode?attendantKeyKey=${attendantKeyKey}&id=${id}&type=${type}`);
    else if (visitorKey)
      return this.http.get(`${environment.patientApiUrl}api/v1/visitors/qrCode?id=${id}&type=${type}&visitorKey=${visitorKey}`);
  }

  sendOtpToGetEcard(patientId, type = '1') {
    return this.http.get(`${environment.patientApiUrl}api/v1/visitors/sendOtpToGetEcard?id=${patientId}&type=${type}`);
  }

  verifyOTP(mobileNo, otp) {
    return this.http.get(`${environment.patientApiUrl}api/v1/visitors/verifyOTP?mobileNumber=${mobileNo}&otp=${otp}`);
  }

  reIssueEcard(body) {
    return this.http.post(`${environment.patientApiUrl}api/v1/visitors/reIssueEcard`, body);
  }

  uploadFile(formData) {
    return this.http.post(`${environment.notificationApiUrl}api/v1/images/uploadFile`, formData, { responseType: 'text' })
  }

  patientApprovalList(pageInfo: PaginationModel, branchId, customerId, approvalStatusId?, departmentId?) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/unApprovedPatient?branchId=${branchId}&customerId=${customerId}&page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  patientUpdateRequestList(pageInfo: PaginationModel, branchId, customerId, approvalStatusId?, departmentId?) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/updateRequest?branchId=${branchId}&customerId=${customerId}&page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  updateApproval(patientId, body) {
    return this.http.put(`${environment.patientApiUrl}api/v1/patient/${patientId}/updateStatus`, body);
  }

  onboardApproval(body) {
    return this.http.put(`${environment.patientApiUrl}api/v1/patient/updateOnboardStatus`, body);
  }

  bulkOnboardApproval(status, ids, body = {}) {
    const patientIds = ids.join(',');
    let params = new HttpParams().append('id', patientIds);
    return this.http.put(`${environment.patientApiUrl}api/v1/patient/patientBulkApproval/${status}`, body, { params: params });
  }

  getPatientUpdateRequestList(patientUpdateRequestId) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/updateRequest/${patientUpdateRequestId}`);
  }

  sendOtpToVerifyMobileNumber(mobileNumber, patientName, email?) {
    let params = new HttpParams();
    params = params.append("name", patientName);
    let customerName = localStorage.getItem("customerName");
    params = params.append("customerName", customerName);
    if (email)
      params = params.append("email", email);
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/sendOtpToVerifyMobileNumber?mobileNumber=${mobileNumber}&key=Healthcare_Patient`, { params: params });
  }

  resendOtpToVerifyMobileNumber(mobileNumber, patientName, email?) {
    let params = new HttpParams();
    params = params.append("name", patientName);
    let customerName = localStorage.getItem("customerName");
    params = params.append("customerName", customerName);
    if (email)
      params = params.append("email", email);
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/resendOtpToVerifyMobileNumber?mobileNumber=${mobileNumber}`, { params: params });
  }

  verifyMobileNumber(mobileNumber, otp) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/verifyMobileNumber?mobileNumber=${mobileNumber}&otp=${otp}`);
  }

  patientConfigList(pageInfo: PaginationModel, branchId, customerId, body = {}) {
    return this.http.get(`${environment.patientApiUrl}api/v1/configuration?branchId=${branchId}&customerId=${customerId}&page=${pageInfo.
      page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.searchText)}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  addPatientConfig(payload) {
    return this.http.post(`${environment.patientApiUrl}api/v1/configuration`, payload)
  }

  getPatientConfigById(id) {
    return this.http.get(`${environment.patientApiUrl}api/v1/configuration/viewDetails/${id}`)
  }

  getUserByStaffId(staffId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/getById/${staffId}`);
  }

  getStaffByUserId(userId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/getStaffByUserId/${userId}`);
  }
  
  updatePatientConfig(payload, id) {
    return this.http.put(`${environment.patientApiUrl}api/v1/configuration/updateConfig/${id}`, payload);
  }

  backlistVisitorList(pageInfo: PaginationModel, branchId, customerId, body = {}) {
    return this.http.get(`${environment.patientApiUrl}api/v1/blacklistvisitor?page=${pageInfo.page}&size=${pageInfo.size}&sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}`);
  }

  visitingInfoList(pageInfo: PaginationModel, branchId, customerId, body = {}) {
    return this.http.get(`${environment.patientApiUrl}api/v1/visitingInfo?bid=${branchId}&cid=${customerId}&by=${pageInfo.sortBy}&order=${pageInfo.sortDir}&page=${pageInfo.page}&size=${pageInfo.size}`);
  }

  addVisitingHours(body) {
    return this.http.post(`${environment.patientApiUrl}api/v1/visitingInfo`, body);
  }

  addBlacklistVisitor(body) {
    return this.http.post(`${environment.patientApiUrl}api/v1/blacklistvisitor`, body);
  }

  updateBlacklistVisitor(id, status, body) {
    return this.http.put(`${environment.patientApiUrl}api/v1/blacklistvisitor/updateBlacklistStatus?id=${id}&status=${status}`, body);
  }

  getVisitingHoursById(visitingInfoId) {
    return this.http.get(`${environment.patientApiUrl}api/v1/visitingInfo/${visitingInfoId}`);
  }

  updateVisitingHoursById(visitingInfoId, body) {
    return this.http.put(`${environment.patientApiUrl}api/v1/visitingInfo/${visitingInfoId}`, body);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      // this.isPrinting = false;
      // this.router.navigate(['admin/seat-inventory/seat']);
    });
  }

  addWhitelistedVisitor(payload) {
    return this.http.post(`${environment.patientApiUrl}api/v1/patient/addWhitelistedVisitor`, payload)
  }

  getWhitelistedVisitors(patientId) {
    return this.http.get(`${environment.patientApiUrl}api/v1/patient/getWhiteListedVisitors?patientId=${patientId}`);
  }

  getWorkFlowById(workFlowRuleId) {
    return this.http.get(`${environment.authApiUrl}api/v1/workFlowRule/${workFlowRuleId}`)
  }

  getWorkFlowList(pageInfo) {
    return this.http.get(`${environment.authApiUrl}api/v1/workFlowRule/WorkFlowRuleListing?sortBy=${pageInfo.sortBy}&sortDirection=${pageInfo.sortDir}&page=${pageInfo.page}&size=${pageInfo.size}&searchText=${encodeURIComponent(this.common.
      searchText.toLowerCase())}`);
  }

  updateWorkFlow(body) {
    return this.http.put(`${environment.authApiUrl}api/v1/workFlowRule`, body);
  }

  dischargePatient(id, status, body) {
    return this.http.put(`${environment.patientApiUrl}api/v1/patient/dischargePatient/${id}/${status}`, body)
  }

  getBedByRoom(customerId, branchId, roomId, departmentId = "", occupy = false) {
    return this.http.get(`${environment.customerApiUrl}api/v1/seatBed/picklistByRoom?branchId=${branchId}&customerId=${customerId}&departmentId=${departmentId}&roomId=${roomId}&occupy=${occupy}`);
  }

  getReferPhysicianList(customerId, branchId) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/pickListReferPhysician?customerId=${customerId}`);
  }

  getVisitType() {
    return this.http.get(`${environment.patientApiUrl}api/v1/visitingInfo/visit-type`);
  }

  checkCareTakeMemberisRequiredOrNot() {
    return this.http.get(`${environment.authApiUrl}api/v1/workFlowRule/types/PATIENT_CARETEAM_FLOW_MANDATORY`);
  }

  reOnboardPatient(body) {
    return this.http.put(`${environment.patientApiUrl}api/v1/patient/setStatusToInitiated`, body)
  }
}