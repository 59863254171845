import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { common } from 'src/app/config/enum';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { environment } from 'src/app/config/environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  public roleStatus = new Subject();
  public roleStatusObservable = this.roleStatus.asObservable();
  constructor(private http: HttpClient, private common: CommonServiceService) { }


  saverole(Credential) {
    return this.http.post(environment.newApiUrl + 'roles', Credential);
  }

  getrole(pageInfo: PaginationModel, customerId, status) {
    if (status === false || status === true)
      return this.http.get(environment.newApiUrl + 'roles?customerId=' + customerId + '&page=' + pageInfo.page + '&searchText=' + encodeURIComponent(this.common.searchText) + '&size=' + pageInfo.size + '&sortBy=' + pageInfo.sortBy + '&sortDirection=' + pageInfo.sortDir + '&status=' + status);
    else
      return this.http.get(environment.newApiUrl + 'roles?customerId=' + customerId + '&page=' + pageInfo.page + '&searchText=' + encodeURIComponent(this.common.searchText) + '&size=' + pageInfo.size + '&sortBy=' + pageInfo.sortBy + '&sortDirection=' + pageInfo.sortDir);
  }

  deleterole(id) {
    return this.http.put(environment.newApiUrl + 'api/v1/roles/' + id, id);
  }
  getroledetails(id) {
    return this.http.get(environment.newApiUrl + 'roles/' + id, id);
  }

  updaterole(id, Credential) {
    return this.http.put(environment.newApiUrl + 'roles/update/' + id, Credential);
  }

  downloadTemplate() {
    return this.http.get(`${environment.authApiUrl}api/v1/bulk-upload/role-template`, { responseType: 'blob' as 'json' });
  }

  bulkUploadFile(formData) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return this.http.post(`${environment.authApiUrl}api/v1/bulk-upload/role-validate`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, observe: 'response', responseType: 'blob' as 'json' });
  }

  uploadBulk(bulkUploadId, formData?) {
    return this.http.post(`${environment.authApiUrl}api/v1/bulk-upload/role-excel/${bulkUploadId}`, {});
  }

  deleteAllBulkUploadErrorRecords(csvId: number) {
    return this.http.delete(`${environment.customerApiUrl}api/v1/staff/deleteIncorrectRows?csvId=${csvId}`);
  }

  downloadSampleCsv() {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/downloadFile`, { responseType: 'blob' as 'json' });
  }

  removeAllTemplateData(csvId: number) {
    return this.http.delete(`${environment.customerApiUrl}api/v1/staff/deleteCsv?csvId=${csvId}`);
  }

  uploadBulkRecords(csvId: number, machineModified) {
    return this.http.post(`${environment.customerApiUrl}api/v1/staff/finishBulkUpload?csvId=${csvId}&machineModified=${machineModified}`, null);
  }
  updateRowOfBulkUpdateError(response: any) {
    return this.http.put(`${environment.customerApiUrl}api/v1/staff/editTempRow/${response.id}`, response);
  }
  getIncorrectRecordCsv(csvId: number, pageInfo: PaginationModel) {
    return this.http.get(`${environment.customerApiUrl}api/v1/staff/incorrectRecordsCsv?csvId=${csvId}&page=${pageInfo.
      page}&size=${pageInfo.size}`);
  }

  removeCsvSingleRow(csvId: number) {
    return this.http.delete(`${environment.customerApiUrl}api/v1/staff/deleteTempUser?tempUserId=${csvId}`);
  }
  changeRoleStatus(ids, status) {
    const roleIds = ids.join(',');
    let params = new HttpParams().append('ids', roleIds)
    params = params.append('status', status);
    return this.http.put(environment.authApiUrl + `api/v1/roles/bulkUpdate`,{}, { params: params });
  }
}
