import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/core/common-service.service';
import { LoaderService } from 'src/app/modules/shared/services/loader.service';
import { PaginationModel } from 'src/app/modules/shared/model/pagination-model';
import { ToastService } from 'src/app/modules/shared/services/toaster.service';
import { DialogService } from 'src/app/core/dialog.service';
import { ToasterComponent } from 'src/app/modules/shared/component/toaster/toaster.component';
import { ErrorToasterComponent } from 'src/app/modules/shared/component/error-toaster/error-toaster.component';
import { WorkOrderService } from '../../services/work-order.service';
import { SuccessDialogComponent } from 'src/app/modules/shared/component/success-dialog/success-dialog';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/component/confirmation-dialog/confirmation-dialog.component';


// ----------component--------------------------------------------------------------------------------------//
@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.html',
  styleUrls: ['./work-order.scss']
})

export class WorkOrderComponent implements OnInit {
  // isActive = true;
  searchText = '';
  message;
  actionsToggle = false;
  deleteButton: boolean;
  isActive: boolean;
  isList = true;
  screenWidth: number;
  showMobileTable: boolean;
  ipAddress: any;
  filterValue: any;
  filterView: boolean = false;
  isFilterImplemented: boolean = false;
  @ViewChild('actionButtonToggle', { static: false }) actionButtonToggle: ElementRef;
  @ViewChild('filterMenuToggle', { static: false }) filterMenuToggle: ElementRef;
  isCalendarOpen: boolean;
  public pageInfo: PaginationModel = new PaginationModel();
  constructor(
    private commonService: CommonServiceService,
    private router: Router,
    private loaderService: LoaderService,
    private matDialog: MatDialog,
    public toastService: ToastService,
    private renderer: Renderer2,
    private workOrderService: WorkOrderService) {
    this.pageInfo.size = 10;
    this.pageInfo.page = 0;
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.showMobileTable = true;
    } else {
      this.showMobileTable = false;
    }
  }

  toggleAction() {
    this.actionsToggle = !this.actionsToggle;
  }
  ngOnInit() {
    this.commonService.searchText = '';

    this.commonService.callToggle.subscribe((data) => {
      this.change();
    });

    if (localStorage.getItem('isActive') === 'true') {
      this.isActive = true;
    } else {
      this.isActive = false;
    }

    this.renderer.listen('window', 'click', (e: Event) => {
      // if (this.isList) {
      if (this.actionsToggle) {
        if (!this.actionButtonToggle.nativeElement.contains(e.target)) {
          this.actionsToggle = false;
        }
      }
      if (this.filterView && !this.isCalendarOpen) {
        if (!this.filterMenuToggle.nativeElement.contains(e.target)) {
          this.filterView = false;
        }
      }
      // }
    });

  }

  searchItem() {
    this.commonService.sendFilterCloseEvent();
    this.commonService.searchText = this.searchText.trim();
    this.commonService.sendSearchEvent();

  }
  searchInputChanged() {
    this.commonService.searchText = this.searchText;
  }

  // -----toggle--------------------------------------------------------------------------------------//
  change() {
    this.isActive = !this.isActive;
  }
  listGridView() {
    this.isList = !this.isList;
  }

  filterValueChange(event) {
    this.filterView = false;
    this.isFilterImplemented = true;
    this.filterValue = event;
    this.checkIfFilterImplemented();
  }
  filterClosed(event) {
    this.filterView = false;
    this.filterValue = event;
    this.checkIfFilterImplemented();

  }
  closeFilterEventWithoutDataChange() {
    this.filterView = !this.filterView;
  }
  checkIfFilterImplemented() {
    if (this.filterValue) {
      if ((this.filterValue.length > 0)) {
        this.isFilterImplemented = true;
      } else {
        this.isFilterImplemented = false;
      }
    }
  }

  changeStatus(status) {
    if (this.workOrderService.selectedWorkOrdersList.length) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data:
        {
          yesBtnName: 'Confirm',
          noBtnName: 'Cancel',
          confirmationMesg: `Are you sure you want to delete selected tickets?`,
          heading: `Confirmation`
        }
      }).afterClosed().subscribe(res => {
        if (res === 1) {
          this.loaderService.show();
          this.workOrderService.updateStatus(this.workOrderService.selectedWorkOrdersList, status).subscribe(res => {
            this.loaderService.hide();
            this.openSuccessDialog(res['description'], 'Success');
          }, err => {
            this.errorSnackBar(res);
          })
        }
      })
    }
    else {
      this.matDialog.open(ConfirmationDialogComponent, { data: { heading: ' ', confirmationMesg: 'Please select data first', yesBtnName: 'Ok' } })
        .afterClosed()
        .subscribe((response) => {
        });
    }
  }

  openSuccessDialog(message, heading) {
    this.matDialog.open(SuccessDialogComponent,
      {
        data:
        {
          yesBtnName: 'OK',
          confirmationMesg: message,
          heading: heading
        }
      }).afterClosed().subscribe(res => {
        this.workOrderService.selectedWorkOrders.next([]);
      })
  }

  checkCalendarOpen(value) {
    this.isCalendarOpen = value;
  }

  deleteWorkOrder() {
    if (this.workOrderService.selectedWorkOrdersList.length) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data:
        {
          yesBtnName: 'Confirm',
          noBtnName: 'Cancel',
          confirmationMesg: `Are you sure you want to delete workorder?`,
          heading: `Confirmation`
        }
      }).afterClosed().subscribe((res: any) => {
        if (res === 1) {
          this.loaderService.show();
          this.workOrderService.deleteWorkOrder(this.workOrderService.selectedWorkOrdersList).subscribe(res => {
            this.loaderService.hide();
            this.openSuccessDialog(res['description'], 'Success');
          })
        }
      })
    } else {
      this.matDialog.open(ConfirmationDialogComponent, {
        data:
        {
          heading: ' ',
          confirmationMesg: 'Please select data first',
          yesBtnName: 'Ok'
        }
      })
        .afterClosed()
        .subscribe((response) => {
          if (response) {
          } else {

          }
        });
    }
  }

  openSnackBar(message) {
    this.toastService.opentoast(
      { toastName: 'success', data: { name: '', message } },
      ToasterComponent
    );
  }

  errorSnackBar(error) {
    this.toastService.opentoast(
      { toastName: '', data: { errorCode: error.status, error: error.error } },
      ErrorToasterComponent
    )
  }
}
