import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../config/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PicklistService {
  constructor(
    private httpClient: HttpClient
  ) { }

  getAreaPicklist() {
    const branchId = localStorage.getItem('branchId');
    const customerId = localStorage.getItem('customerId');
    return this.httpClient.get(`${environment.visitorUrl}area?branchId=${branchId}&customerId=${customerId}`);
  }

  getVisitorCategoryPicklist() {
    const branchId = localStorage.getItem('branchId');
    const customerId = localStorage.getItem('customerId');
    return this.httpClient.get(`${environment.visitorUrl}visitCategory?branchId=${branchId}&customerId=${customerId}`);
  }

  getRole(customerId, roleId?) {
    if (roleId)
      return this.httpClient.get(`${environment.customerApiUrl}roles/pickList?customerId=${customerId}&roleId=${roleId}`);
    else
      return this.httpClient.get(`${environment.authApiUrl}api/v1/roles/pickList?customerId=${customerId}`);
  }

  getDepartment(branchId, customerId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/department/picklist?branchId=${branchId}&customerId=${customerId}`);
  }

  getDepartmentByRole(branchId, roleId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/department/picklistbyrole?branchId=${branchId}&roleId=${roleId}`);
  }

  getDoctor(branchId, customerId, departmentId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/staff/getDoctorListing?branchId=${branchId}&customerId=${customerId}&departmentId=${departmentId}`);
  }

  getRoomByDept(customerId, branchId, departmentId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/room/picklistByDept?branchId=${branchId}&customerId=${customerId}&departmentId=${departmentId}`);
  }

  getRoomForPatientByDept(customerId, branchId, departmentId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/room/forPatient/picklistByDept?branchId=${branchId}&customerId=${customerId}&departmentId=${departmentId}`);
  }
  getBedByRoom(customerId, branchId, departmentId, roomId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/seatBed/picklistByRoom?branchId=${branchId}&customerId=${customerId}&departmentId=${departmentId}&roomId=${roomId}`);
  }

  getDepartmentByBranchBuildingFloor(branchId, buildingId, floorId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/department/picklistOfDepartment?branchId=${branchId}&buildingId=${buildingId}&floorId=${floorId}`);
  }

  getBranchBuilding(customerId, searchText): Observable<any> {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/branch/branchBuilding?branchName=${searchText}&customerId=${customerId}`);
  }

  getBranch(customerId): Observable<any> {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/branch/picklist?customerId=${customerId}`);
  }


  getBranchByIndustry(industryId, customerId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/branch/picklist?industryId=${industryId}&customerId=${customerId}`);
  }

  getBuilding(branchId, customerId?) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/building/picklist/buildingByBranch?branchId=${branchId}`);
  }

  getFloor(branchId, buildingId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/floor/picklist?branchId=${branchId}&buildingId=${buildingId}`);
  }

  getRoom(branchId, buildingId, departmentId, floorId, industryId = '') {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/room/picklist?branchId=${branchId}&buildingId=${buildingId}&departmentId=${departmentId}&floorId=${floorId}&industryId=${industryId}`);
  }
  getRoomType(customerId): Observable<any> {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/room/roomTypePicklist?customerId=${customerId}`);
  }

  getSeatBedType(customerId): Observable<any> {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/seatBed/seatBedTypePicklist?customerId=${customerId}`);
  }

  getStaff(customerId, searchText = '', roles?, branchId?) {
    const customerCode = localStorage.getItem("customerCode");
    let params = new HttpParams();
    if (roles) {
      const role = roles.join(',');
      params = params.append('roles', role);
    }
    if (branchId) {
      let branchIds = [];
      branchIds.push(branchId);
      // let branchIdsParam = branchIds.join(',');
      params = params.append('branchIds', branchIds.join(','));
    }
    if (customerCode == "PAS") {
      params = params.append('type', "PAS");
    }
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/staff/pickList?customerId=${customerId}&searchText=${searchText}`, { params: params });
  }

  getSupervisorStaff(customerId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/staff/staffListingForCustomer?customerId=${customerId}`);
  }

  getAdminStaff(customerId, searchText?) {
    // if (searchText)
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/staff/branchAdminpickList?customerId=${customerId}&searchText=${searchText}`);
    // else
    //   return this.httpClient.get(`${environment.customerApiUrl}api/v1/staff/branchAdminpickList?customerId=${customerId}`);
  }

  getStaffByBranchBuildingFloor(customerId, branchId, buildingId, floorId, searchText?) {
    if (searchText)
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/staff/pickListStaff?branchId=${branchId}&buildingId=${buildingId}&floorId=${floorId}&customerId=${customerId}&searchText=${searchText}`);
    else
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/staff/pickListStaff?branchId=${branchId}&buildingId=${buildingId}&floorId=${floorId}&customerId=${customerId}`);
  }

  getCountry() {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/configs/countries`);
  }

  getState(countryId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/configs/state?countryId=${countryId}`);
  }

  getCity(stateId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/configs/cities?stateId=${stateId}`);
  }

  getDesignation(customerId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/designation/pickList?customerId=${customerId}`);
  }
  getSpecialisation(customerId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/specialisation/pickList?customerId=${customerId}`);
  }

  getSkills(customerId) {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/skills/pickList?customerId=${customerId}`);
  }

  getStatusPicklist() {
    return this.httpClient.get(`${environment.customerApiUrl}api/v1/configs/status`);
  }

  getRolePicklist(organizationId?: number, roleId?: number) {
    if (roleId && roleId >= 0) {
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/roles/pickList/${organizationId}?roleId=${roleId}`);
    } else {
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/roles/pickList/${organizationId}`);
    }
  }

  getLocationPicklist(organizationId?: number, locationId?: number) {
    if (locationId && locationId >= 0) {
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/locations/pickList/${organizationId}?locationId=${locationId}`);
    } else {
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/locations/pickList/${organizationId}`);
    }
  }

  getDesignationsPicklist(organizationId?: number, designationsId?: number) {
    if (designationsId && designationsId >= 0) {
      return this.httpClient
        .get(`${environment.customerApiUrl}api/v1/designations/pickList?organizationId=${organizationId}&dsgId=${designationsId}`);
    } else {
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/designations/pickList?organizationId=${organizationId}`);
    }
  }

  getSkillsPicklist(skillIds?: number[]) {
    if (skillIds && skillIds.length) {
      let skillIdsParameter = '';
      skillIds.map(x => {
        skillIdsParameter = `${skillIdsParameter}skillIds=${x}&`;
      });
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/skills/pickList?${skillIdsParameter}`);
    } else {
      return this.httpClient.get(`${environment.customerApiUrl}api/v1/skills/pickList`);
    }
  }

  uploadFile(formData) {
    return this.httpClient.post(`${environment.notificationApiUrl}api/v1/images/uploadFile`, formData, { responseType: 'text' })
  }

  getImage(key) {
    return this.httpClient.get(`${environment.notificationApiUrl}api/v1/images/getImage?key=${key}`, { responseType: 'blob' });
  }

  getUploadedFile(key) {
    return this.httpClient.get(`${environment.notificationApiUrl}api/v1/images/getImage?key=${key}`, { responseType: 'blob', observe: 'response' });
  }

  getPdf(key) {
    return this.httpClient.get(`${environment.notificationApiUrl}api/v1/images/getPdf?key=${key}`, { responseType: 'blob' });
  }

  getKeyPicklist(customerId) {
    return this.httpClient.get(`${environment.patientApiUrl}api/v1/configuration/getKeyPicklist/${customerId}`)
  }

  getGender() {
    return this.httpClient.get(`${environment.patientApiUrl}api/v1/gender`);
  }

  getMeetingRoomType() {
    return this.httpClient.get(`${environment.apiBaseUrl}inventory/api/v1/meetingRoomType/web`);
  }
  getSeatTypeList() {
    return this.httpClient.get(`${environment.apiBaseUrl}inventory/api/v1/seattypes?onlyParent=true&size=10000`)
  }
  getSeatClassificationList() {
    return this.httpClient.get(`${environment.apiBaseUrl}inventory/api/v1/seats/classifications?size=100000`)
  }
  getSeatFilterList() {
    return this.httpClient.get(`${environment.apiBaseUrl}inventory/api/v1/filters?type=SEAT&size=100000`)
  }
  getSeatPicklist(roomId) {
    return this.httpClient.get(`${environment.apiBaseUrl}inventory/api/v1/inventory/seat/seatPicklist?roomId=${roomId}`)
  }
  getServiceList() {
    return this.httpClient.get(`${environment.apiBaseUrl}inventory/api/v1/service-types`)
  }

  getAllBranch() {
    return this.httpClient.get(`${environment.authApiUrl}api/v1/users/getAllBranch`);
  }

  convertMinToHr(minutes) {
    const hrs = Math.floor(minutes / 60);
    let mins = minutes - hrs * 60;
    if (minutes / 60 > 0) {
      if (mins == 0) {
        mins = 0;
      }
    }
    return { hrs: hrs, mins }
  }

  getIndustryList() {
    return this.httpClient.get(`${environment.authApiUrl}api/v1/industries`);
  }
}
